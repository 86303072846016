@tailwind base;
@tailwind components;
@tailwind utilities;

@layer utilities {
  .gradient-text {
    background: linear-gradient(180deg, #0800ff, #00aaff);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    color: transparent;
    /* Fallback color */
  }}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
/* Prevent right-click context menu on video elements */
video {
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}
